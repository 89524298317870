import gsap from "gsap";

const componentAnimation = () => {
    
    const dataAnimations = document.querySelectorAll("[data-animation='default']");

    if (dataAnimations) {
        dataAnimations.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 0, y: 100});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 1, opacity: 1, y: 0});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }


    const dataAnimations2 = document.querySelectorAll("[data-animation='sepia']");

    if (dataAnimations2) {
        dataAnimations2.forEach(function (dataAnimation2) {
            gsap.set(dataAnimation2, { filter: 'sepia(1) grayscale(0.8)' });
    
            const observeOptions = {
                root: null,
                threshold: 0.65, // Setze den Schwellenwert auf 75%
                // rootMargin: "-150px"
            };
    
            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.65) {
                        gsap.to(entry.target, { duration: 0.7, filter: 'sepia(0) grayscale(0)', ease: 'power1.inOut' });
                    } else {
                        gsap.to(entry.target, { duration: 0.7, filter: 'sepia(1) grayscale(0.8)', ease: 'power1.inOut' });
                    }
                });
            }, observeOptions);
    
            observeAni.observe(dataAnimation2);
        });
    }
    



    



};

export default componentAnimation;
